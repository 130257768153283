<template>
  <label
    class="ui-checkbox"
    :class="{disable: props.disable}"
  >
    <div class="ui-checkbox__inner">
      <input
        v-model="isChecked"
        class="ui-checkbox__native"
        type="checkbox"
      >
      <div class="ui-checkbox__bg">
        <svg
          class="ui-checkbox__svg"
          viewBox="0 0 24 24"
          aria-hidden="true"
        >
          <path
            class="ui-checkbox__truthy"
            fill="none"
            d="M1.73,12.91 8.1,19.28 22.79,4.59"
          />
          <path
            class="ui-checkbox__indet"
            d="M4,14H20V10H4"
          />
        </svg>
      </div>
    </div>
    <div class="q-checkbox__label">
      <slot />
    </div>
  </label>
</template>

<script lang="ts" setup>
import { computed } from '#imports'

const emits = defineEmits(['update:modelValue'])
const props = defineProps<{
  modelValue?: boolean,
  disable?: boolean
}>()

const isChecked = computed({
  get () {
    return props.modelValue
  },
  set (v) {
    emits('update:modelValue', v)
  },
})
</script>

<style scoped lang="scss">
.ui-checkbox {
  display: flex;
  gap: 8px;
  cursor: pointer;

  &.disable {
    pointer-events: none;
    opacity: 0.7;
  }

  &__bg {
    border: 1px solid #b2c0cd;
    border-radius: 4px;
    width: 20px;
    height: 20px;
  }

  &__truthy {
    stroke: #fff;
    stroke-width: 3.12px;
    stroke-dashoffset: 29.78334;
    stroke-dasharray: 29.78334;
  }

  &__native {
    display: none;
  }

  &__indet {
    fill: #fff;
    transform: rotate(-280deg) scale(0);
    transform-origin: 50% 50%;
  }

  &__svg {
    display: block;
    padding: 4px;
  }

  input:checked + &__bg {
    background: $primary;

    path {
      stroke-dashoffset: 0;
      transition: stroke-dashoffset .18s cubic-bezier(.4, 0, .6, 1) 0ms;
    }
  }
}
</style>
